<template>
  <v-data-table
      class='elevation-1'
      :footer-props='footerProps'
      :headers='headers'
      :items='items'
      item-key='Id'
      :loading='loading'
      :sort-by.sync='pageSortBy'
      sort-desc.sync='false'
  >
    <template v-slot:item.SptDate="{ item }">
      {{ item.SptDate | FormatDateISO }}
    </template>
    <template v-slot:item.actions="{ item }">
      <button
          class='linkBtn'
          type='button'
          @click='viewDlg(item)'
      >
        View
      </button>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['items', 'loading'],
  data() {
    return {
      footerProps: {itemsPerPageOptions: [25, 50]},
      pageSortBy: 'SptDate',
      headers: [
        {
          text: 'Date',
          value: 'SptDate',
          sortable: true,
          width: '5%'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '5%',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    viewDlg(item) {
      this.$emit('view', item);
    }
  }
};
</script>

<style scoped></style>
