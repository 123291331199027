<template>
  <v-container fluid grid-list-xl>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Patient Skin Prick Test Details
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-btn color='primary' v-show='allowAdd' @click='createDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New SPT
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <div class='filterStyle'>
            <v-text-field label='Patient Id:' v-model='patientId'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <v-flex>
            <v-btn :disabled='loading' @click='searchPatient'>
              <v-icon class='iconText'>fa fa-search</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <v-flex xs6 v-show='patient.Id'>
            <PatientCard :patient='patient'></PatientCard>
          </v-flex>
          <v-flex xs6 v-show='exam.Id'>
            <ExamSummary :exam='exam'></ExamSummary>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 lg12 v-show='patient.Id'>
        <SmSptDis :obj='items[0]'></SmSptDis>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <div>
          <SptDisplay
            :items='items'
            :loading='loading'
            @view='viewDlg'
          ></SptDisplay>
          <SptAddDlg
            :dlg-type='dlgType'
            :dlg-spt='dlgShow'
            :obj='selectedItem'
            :pid='patient.Id'
            @close='closeDlg'
            @add='addNewItem'
          ></SptAddDlg>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import SptAddDlg from '../../components/spt/SptDlg';
import SmSptDis from '../../components/spt/SmSptDis';
import SptDisplay from '../../components/spt/SptsDisplay';
import PatientCard from '../../components/patients/PatientCard';
import ExamSummary from '../../components/exam/ExamSummary';
import WebH from '../../api/web';
export default {
  components: {
    PatientCard,
    ExamSummary,
    SptDisplay,
    SptAddDlg,
    SmSptDis
  },
  created() {
    this.allowAdd = this.$store.getters['UserStore/permissions'].includes(
      's.a'
    );
    this.patientId = this.$store.getters['SettingsStore/patientId'];
    this.searchPatient();
  },
  data() {
    return {
      allowAdd: false,
      loading: false,
      items: [],
      exam: {},
      patientId: '',
      patient: {},
      dlgShow: false,
      dlgType: 0,
      selectedItem: null
    };
  },
  methods: {
    closeDlg() {
      this.selectedItem = null;
      this.dlgShow = false;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 2;
      this.dlgShow = true;
    },
    // editDlg(item) {
    //   this.selectedItem = Object.assign({}, item);
    //   this.dlgType = 1;
    //   this.dlgShow = true;
    // },
    async createDlg() {
      this.selectedItem = null;
      this.dlgType = 0;
      this.dlgShow = true;
    },
    addNewItem(item) {
      this.items.push(item);
      this.$notify({
        type: 'info',
        text: 'Successfully added new spt'
      });
    },
    // async updateItem() {
    //   await this.$notifyDlg.show(
    //     'Please search again to load the updated details',
    //     'Information',
    //     'information'
    //   );
    // },
    async searchPatient() {
      if (this.patientId === '') {
        return;
      }
      this.$store.commit('SettingsStore/setPatientId', this.patientId);
      this.loading = true;
      this.patient = {};
      this.exam = {};
      this.items = [];
      let url = '/patients/search?&name=&pid=' + this.patientId + '&mobile=';
      const res = await WebH.Get(url, 'patients');
      if (res.Success) {
        if (res.Data.length > 0) {
          this.patient = res.Data[0];
          await this.getRelatedData();
        } else {
          await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        }
      }
      this.loading = false;
    },
    async getRelatedData() {
      this.getExam();
      this.getSPTs();
    },
    async getExam() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.exam = {};
      let url = '/exams/' + this.patient.Id;
      const res = await WebH.Get(url, 'examination');
      if (res.Success) {
        this.exam = res.Data;
      }
      this.loading = false;
    },
    async getSPTs() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.items = [];
      let url = '/spt/' + this.patient.Id + '/all';
      const res = await WebH.Get(url, 'SPTs');
      if (res.Success) {
        this.items = res.Data;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
