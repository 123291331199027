export const sptAllergensMixin = {
  data() {
    return {
      allergens: [
        "Cenchrus ciliaris",
        "Cynodon dactylon",
        "Imperata cylindrica",
        "Maerua arenaria",
        "Pennisetum typholdes",
        "Sorghum vulgare",
        "Adhatoda vasica",
        "Ageratum conyzoides",
        "Amaranthus hybridus",
        "Amaranthus spinosus",
        "Argemone mexicana",
        "Artemisia scoparia",
        "Brassica campestris",
        "Cassia fistula",
        "Cassia occidentails",
        "Chenopodium album",
        "Chenopodium murale",
        "Cyperus rotundus",
        "Dodonaea viscosa",
        "Gynadropsis gynandra",
        "Ipomea fistulosa",
        "Parthenium hysterophorus",
        "Ricinus communis",
        "Suaeda fruticosa",
        "Typha angustata",
        "Xanthium strumarium",
        "Zea mays",
        "Albizia lebbeck",
        "Azardirachta indica",
        "Carica papaya",
        "Cassia siamea",
        "Casuarina equisetifolia",
        "Cocos nucifera",
        "Eucalyptus tereticornis",
        "Holoptelea integrifolia",
        "Kigelia pinnata",
        "Prosopis juliflora",
        "Salvodora persica",
        "Alternaria",
        "Asp. flavus",
        "Asp. fumigatus",
        "Asp. niger",
        "Asp. tamarii",
        "Candida albians",
        "Caldosporium",
        "Curvularia",
        "Epicoccum",
        "Fusarium",
        "Helminthosporium",
        "Mucor mucedo",
        "Pennicilium Sp.",
        "Phoma Tropicalis",
        "Rhizopus",
        "Trichoderma",
        "Der. farinae",
        "Der. pteronyssinus",
        "Blomia tropicalis",
        "Cockroach-A",
        "Cockroach-G",
        "Fire Ant",
        "House fly",
        "Mosquito",
        "Moth",
        "Rise weevil",
        "Silk moth",
        "Buffalo",
        "Cat",
        "Cow",
        "Dog",
        "Human",
        "Soap nut",
        "Cotton",
        "Silk",
        "Paper",
        "Sunflower",
        "Honey Bee",
        "Egg White",
        "Egg Yolk"
      ]
    };
  }
};
