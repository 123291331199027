<template>
  <v-dialog v-model='dlgSpt' scrollable persistent max-width='600px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          {{ dlgType === 0 ? 'Add' : dlgType === 1 ? 'Edit' : 'View' }}
          SPT details
        </div>
      </v-card-title>
      <v-card-text>
        <v-menu
            :disabled='dlgType > 0'
            ref='dtMenu'
            :close-on-content-click='false'
            nudge-right='40'
            offset-y
            max-width='290px'
            min-width='290px'
            v-model='dtMenu'
        >
          <template v-slot:activator='{ on }'>
            <v-text-field
                v-model='dlgModel.SptDate'
                label='Select a date:'
                v-on='on'
                name='rDt'
                readonly
                v-validate='{ required: true }'
                :error='!!errors.has("rDt")'
                :error-messages='errors.first("rDt")'
            >
              <template slot='append'>
                <v-icon class='filterIcon grey--text text--darken-1'
                >fa fa-calendar
                </v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
              v-model='dlgModel.SptDate'
              no-title
              @input='dtMenu = false'
          ></v-date-picker>
        </v-menu>
        <v-layout row wrap>
          <v-flex xs6>Control</v-flex>
          <v-flex xs6>Positive</v-flex>
          <v-flex xs3 style='padding-right: 5px'>
            <v-text-field
                :disabled='dlgType > 0'
                label='Wheal (mm):'
                v-model.number='dlgModel.Control.Wheal'
            ></v-text-field>
          </v-flex>
          <v-flex xs3 style='padding-left: 5px; padding-right: 5px'>
            <v-text-field
                :disabled='dlgType > 0'
                label='Erythema (mm):'
                v-model.number='dlgModel.Control.Erythema'
            ></v-text-field>
          </v-flex>
          <!--<v-flex xs6>Positive</v-flex>-->
          <v-flex xs3 style='padding-left: 5px; padding-right: 5px'>
            <v-text-field
                :disabled='dlgType > 0'
                label='Wheal (mm):'
                v-model.number='dlgModel.Positive.Wheal'
            ></v-text-field>
          </v-flex>
          <v-flex xs3 style='padding-left: 5px'>
            <v-text-field
                :disabled='dlgType > 0'
                label='Erythema (mm):'
                v-model.number='dlgModel.Positive.Erythema'
            ></v-text-field>
          </v-flex>

          <v-flex xs6 v-show='dlgType === 0' style='padding-right: 5px'>
            <v-autocomplete
                label='Allergen:'
                v-model='allergen.Name'
                :items='allergens'
                clearable
            ></v-autocomplete>
          </v-flex>
          <v-flex
              xs3
              v-show='dlgType === 0'
              style='padding-left: 5px;padding-right: 5px;'
          >
            <v-text-field
                label='Induration (mm):'
                v-model.number='allergen.Reading'
            ></v-text-field>
          </v-flex>
          <v-flex xs3 v-show='dlgType === 0' style='padding-left: 5px;'>
            <v-btn @click='addItem'>Add Allergen</v-btn>
          </v-flex>
          <v-flex xs12>
            <br/>
            <v-data-table
                :headers='headers'
                :items='dlgModel.Allergens'
                hide-default-footer
                class='elevation-2'
                style='height: 200px;overflow: auto'
            >
              <template v-slot:item.actions="{ item }">
                <tr>
                  <td style='text-align: center'>
                    <button
                        :disabled='dlgType > 0'
                        class='linkBtn'
                        type='button'
                        @click='removeItem(item)'
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
            color='primary darken-1'
            text
            :loading='submit.working'
            @click='saveDlg'
            :disabled='dlgType > 0'
        >Save
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';
import {sptAllergensMixin} from '../../api/sptAllergensMixin';

export default {
  mixins: [sptAllergensMixin],
  props: {
    pid: {type: Number, default: 0},
    dlgSpt: {type: Boolean, default: false},
    dlgType: {
      type: Number,
      default: 0
    },
    obj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    dlgSpt: function () {
      if (this.obj != null && this.obj.Id) {
        this.dlgModel = {
          Control: {
            Wheal: this.obj.Control.Wheal,
            Erythema: this.obj.Control.Erythema
          },
          Positive: {
            Wheal: this.obj.Positive.Wheal,
            Erythema: this.obj.Positive.Erythema
          },
          Allergens: this.obj.Allergens,
          SptDate: this.$options.filters.FormatDateISO(this.obj.SptDate)
        };
      }
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Allergen',
          value: 'Name',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Induration (mm)',
          value: 'Reading',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '5%',
          align: 'center'
        }
      ],
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dtMenu: false,
      allergen: {
        Name: '',
        Reading: 0
      },
      dlgModel: {
        SptDate: null,
        Control: {Wheal: 0, Erythema: 0},
        Positive: {Wheal: 0, Erythema: 0},
        Allergens: []
      }
    };
  },
  methods: {
    closeDlg() {
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.allergen = {
        Name: '',
        Reading: 0
      };
      this.dlgModel = {
        SptDate: null,
        Control: {Wheal: 0, Erythema: 0},
        Positive: {Wheal: 0, Erythema: 0},
        Allergens: []
      };
      this.$emit('close');
    },
    async addItem() {
      if (!this.allergen.Name) {
        await this.ShowMsg('Allergen name required');
        return;
      }
      if (!this.allergen.Reading) {
        await this.ShowMsg('Induration is required');
        return;
      }
      if (!Number(this.allergen.Reading)) {
        await this.ShowMsg('Invalid induration');
        return;
      }
      this.dlgModel.Allergens.push(Object.assign({}, this.allergen));
      this.allergen = {Name: '', Reading: 0};
    },
    removeItem(item) {
      const index = this.dlgModel.Allergens.indexOf(item);
      if (index > -1) {
        this.dlgModel.Allergens.splice(index, 1);
      }
    },
    async ShowMsg(msg) {
      await this.$notifyDlg.show(msg, 'Error', 'error');
    },
    async saveDlg() {
      if (this.pid < 1 && this.dlgType === 0) {
        await this.$notifyDlg.show('Invalid patient', 'Error', 'error');
        return;
      }
      if (this.dlgModel.Allergens.length < 1) {
        await this.$notifyDlg.show(
            'Add allergen before saving',
            'Error',
            'error'
        );
        return;
      }
      if (this.dlgType === 0) {
        this.dlgModel.PId = this.pid;
        this.api_create(this.dlgModel);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/spt', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
