<template>
  <v-card>
    <v-card-title>
      <div class='title font-weight-medium'>
        Latest SPT details
      </div>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model='dlgModel.SptDate'
        label='Spt date:'
        name='rDt'
        readonly
        v-validate='{ required: true }'
        :error='!!errors.has("rDt")'
        :error-messages='errors.first("rDt")'
      >
        <template slot='append'>
          <v-icon class='filterIcon grey--text text--darken-1'
            >fa fa-calendar</v-icon
          >
        </template>
      </v-text-field>
      <v-layout row wrap>
        <v-flex xs6>Control</v-flex>
        <v-flex xs6>Positive</v-flex>
        <v-flex xs3 style='padding-right: 5px'>
          <v-text-field
            readonly
            label='Wheal (mm):'
            v-model.number='dlgModel.Control.Wheal'
          ></v-text-field>
        </v-flex>
        <v-flex xs3 style='padding-left: 5px; padding-right: 5px'>
          <v-text-field
            readonly
            label='Erythema (mm):'
            v-model.number='dlgModel.Control.Erythema'
          ></v-text-field>
        </v-flex>
        <!--<v-flex xs6>Positive</v-flex>-->
        <v-flex xs3 style='padding-left: 5px; padding-right: 5px'>
          <v-text-field
            readonly
            label='Wheal (mm):'
            v-model.number='dlgModel.Positive.Wheal'
          ></v-text-field>
        </v-flex>
        <v-flex xs3 style='padding-left: 5px'>
          <v-text-field
            readonly
            label='Erythema (mm):'
            v-model.number='dlgModel.Positive.Erythema'
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <br />
          <v-data-table
            :headers='headers'
            :items='dlgModel.Allergens'
            hide-default-footer
            class='elevation-0'
            style='height: 200px;overflow: auto'
          >
            <template slot='items' slot-scope='props'>
              <tr>
                <td>{{ props.item.Name }}</td>
                <td>{{ props.item.Reading }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    obj: function() {
      if (this.obj != null && this.obj.Id) {
        this.dlgModel = {
          Control: {
            Wheal: this.obj.Control.Wheal,
            Erythema: this.obj.Control.Erythema
          },
          Positive: {
            Wheal: this.obj.Positive.Wheal,
            Erythema: this.obj.Positive.Erythema
          },
          Allergens: this.obj.Allergens,
          SptDate: this.$options.filters.FormatDateISO(this.obj.SptDate)
        };
      }
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Allergen',
          value: 'Name',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Induration (mm)',
          value: 'Reading',
          sortable: true,
          width: '10%'
        }
      ],
      dlgModel: {
        SptDate: null,
        Control: { Wheal: 0, Erythema: 0 },
        Positive: { Wheal: 0, Erythema: 0 },
        Allergens: []
      }
    };
  }
};
</script>

<style scoped></style>
